import { useGoogleOneTapLogin } from 'react-google-one-tap-login'

const useGoogleOneTap = ({isConnected}) => {
  useGoogleOneTapLogin({
    onError: error => console.log('eroor', error),
    onSuccess: response => console.log('res', response),
    disabled: isConnected,
    googleAccountConfigs: {
      client_id: process.env.REACT_APP_GOOGLE_APP_ID,
    },
  })
}
export default useGoogleOneTap
