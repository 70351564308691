/* @flow */
import { Storage } from '@capacitor/storage'

export default key => ({
  async set(value) {
    try {
      await Storage.set({
        key,
        value: JSON.stringify(value),
      })
    } catch (err) {
      console.error('Unable to store data securely. Error: ', err)
    }

    return value
  },

  async get() {
    const data = await Storage.get({ key })
    try {
      return JSON.parse(data?.value)
    } catch (error) {
      console.warn(`Error parsing stored value for key "${key}"`)
      throw error
    }
  },

  async remove() {
    await Storage.remove({ key })
  },
})
